import React from "react"
import { RadioBox } from "./helper";
import { Translate } from "react-localize-redux";

const ans = [
    {top: '1', bottom: 'B'},
    {top: '2', bottom: 'L'},
    {top: '3', bottom: 'B'},
    {top: '4', bottom: 'T'},
    {top: '5', bottom: 'T'},
    {top: '6', bottom: 'L'},
    {top: '7', bottom: 'R'},
    {top: '8', bottom: 'L'},
    {top: '9', bottom: 'R'},
]
export class StereoDepthVision extends RadioBox {
    constructor(props) {
        super(props, props.setState)
    }
	render() {
		return (
			<Translate key={this.props.template.id}>
				{({ translate }) => 
					<tr>
						<td colSpan="2">
							5. {this.props.template.name}
						</td>
                        {
                            ans.map(x => (
                                <td
                                    key={x.top}
                                    colSpan={x.top % 2 === 1 ? '1': '2'}
                                    className={[x.top <= 4 ? "highlight": '']}
                                >
                                    <label className={this.state.value === `${x.top}` ? 'active': ''}>
                                        <input
                                            name="stereo-depth-vision"
                                            type="radio"
                                            value={`${x.top}`}
                                            onChange={(e) => this.onChangeHandler(e)}
                                            onClick={e => this.onClickHandler(e)}
                                            checked={this.state.value === `${x.top}`}
                                        />
                                        {x.top}<br />
                                        {x.bottom}
                                    </label>
                                </td>
                            ))
                        }
					</tr>
				}
			</Translate>
		)
	}
}