let ThermalPrinter;
let PrinterTypes;

let printer;
let isNative = false;
if (typeof (window.thermal_printer) !== "undefined") {
    isNative = true;
    printer = window.printer; // eslint-disable-line no-undef
    ThermalPrinter = window.thermal_printer.printer; // eslint-disable-line no-undef
    PrinterTypes = window.thermal_printer.types; // eslint-disable-line no-undef
}

class ThermalPrint {
    async print(imageUrl,title,subtitle,text) {
        if(isNative) {
            if(imageUrl) {  
                console.log(imageUrl)
                const request = window.native_request;  // eslint-disable-line no-undef
                const fs = window.native_fs;  // eslint-disable-line no-undef
                const os = window.native_os;  // eslint-disable-line no-undef
                let logo = os.tmpdir()+'\\checkmate-print-logo.png';
                console.log(logo)
                request.head(imageUrl, (err, res, body)=>{
                    if(err) {
                        this.doPrint(null,title,subtitle,text);
                        console.log(err)
                    } else {
                        request(imageUrl).pipe(fs.createWriteStream(logo)).on('close', ()=>{
                            console.log('saved')
                            console.log(logo)
                            this.doPrint(logo,title,subtitle,text);
                        }).on('error',(err)=>{
                            console.log(err)
                            this.doPrint(null,title,subtitle,text);
                        });
                    }
                });  
            } else {
                this.doPrint(null,title,subtitle,text);
            }
        }
    }

    async doPrint(logo,title,subtitle,text) {
        if(isNative) {
            let tprinter = new ThermalPrinter({
                type: PrinterTypes.EPSON,
                interface: 'printer:Receipt',
                characterSet: 'TIS18_THAI',
                driver: printer  // eslint-disable-line no-undef
            });
            
            tprinter.alignCenter();
            if(logo) {
                await tprinter.printImage(logo);  // Print PNG image
                tprinter.println(" ");
            }
            tprinter.setTextDoubleHeight();
            tprinter.setTextDoubleWidth();
            tprinter.println(title);
            tprinter.setTextNormal(); 
            tprinter.println(subtitle);
            tprinter.println('----------');
            
            tprinter.alignLeft();
            tprinter.println(text);
            tprinter.cut();
             
            try {
              let execute = tprinter.execute()
              console.log(execute)
            } catch (error) {
              console.log("Print failed:", error);
            }
        }
    }
}
export default ThermalPrint;