import React, { Component } from "react";
import Barcode from 'react-barcode';

import InlineLoading from '../../components/InlineLoading';

import authFetch from '../../services/authFetch';
import { Translate } from "react-localize-redux";


class RegisterSticker extends Component {
	constructor(props) {
    super(props);
    this.state = {
	    stickers: null
    };
    this.doPrint = this.doPrint.bind(this);
    this.cancelPrint = this.cancelPrint.bind(this);
    this.donePrint = this.donePrint.bind(this);
    
    if(window.stickers) {
	    this.state.stickers = window.stickers;
	  } else {
		  window.close();
	  }
	  
	  //console.log(this.state.stickers)
  }
  
  doPrint()
  {
	  window.print();
  }
  
  cancelPrint()
  {
	  window.cancelPrint();
  }
  
  donePrint()
  {
	  window.donePrint();
  }
    
  componentDidMount() {
	  this.doPrint();
  }

  render() {
    return (
			<Translate>
			{({ translate }) => 
	      <div className="register-sticker">
      		{this.state.stickers!==null  ? (
	      		<div className="sticker-print-wrapper">
	      			<div className="sticker-print-list">
			      		<ul className="sticker-list">
			      			{this.state.stickers.map((item, index)=>{
										return (<li key={ index }>
		      						<strong>{item.no}</strong>
		      						<small>{item.fullname}</small>
		      						<small>{(item.company_name)?item.company_name:"-"}</small>
		      						<small>{item.name}</small>
		      						
		      						<Barcode height="20" width="1" fontSize="9" value={item.id} />
		
		      					</li>)
							    })}
		      			</ul>
	      			</div>
	      			
	      			<div className="sticker-print-buttons">
		      			<h6>{translate('print_sticker')}</h6>
					      <p>
						      <button className="btn-round btn-block" onClick={this.doPrint}>
			      				{translate("print_again")}
		      				</button>
	      				</p>
	      				<br/>
	      				<p>
						      <button className="btn-round btn-block" onClick={this.cancelPrint}>
			      				{translate("cancel_print")}
		      				</button>
	      				</p>
	      				<p>
						      <button className="btn-round btn-black btn-block" onClick={this.donePrint}>
			      				{translate("done_print")}
		      				</button>
	      				</p>
      				</div>
      			</div>
			  	):(
			  		<InlineLoading show={true}/>
			  	)}
	      </div>
      }
			</Translate>
    );
  }
}

export default RegisterSticker;
