import React, { Component } from "react";

import Logout from '../../components/Logout';
import Loading from '../../components/Loading';

import authFetch from '../../services/authFetch';
import { Translate } from "react-localize-redux";

import PDFPrint from "../../helpers/PDFPrint"

class ManagementPEPrint extends Component {
	constructor(props) {
		super(props);

		this.state = {
			vn: this.props.match.params.vn,
			patient: null
		};
		this.goBack = this.goBack.bind(this);
	}

	componentDidMount() {
		authFetch.get('visit/' + this.state.vn + '/print-pe/json', (response) => {
			this.setState({patient:response})
			setTimeout(()=>{
				this.doPrint();
			},300)
		});
	}
	
	doPrint() {
		let printStyle = document.getElementById('specific-print');
		let style = document.createTextNode('@page { size: A4; }body{margin:0; padding:0;}')
		printStyle.appendChild(style)
		window.print();
    }

	goBack() {
		var backTo = '/management/pe_search';
		if (this.props.location.state && this.props.location.state.backTo) {
			backTo = this.props.location.state.backTo;
		}
		this.props.history.push(backTo);
	}

	render() {
		return (
			<Translate>
				{({ translate }) =>
					<div className="management-page page">
						<header className="page-header">
							<button className="link-page-back" onClick={this.goBack}>
								{translate("back")}
							</button>
							<h1>{translate("save_pe_result")}</h1>
							<Logout />
						</header>

						<main className="page-content">
							<div className="page-content-main">
							<div className="register-pe">
								{this.state.patient ? (
									<div className="pe-print-wrapper">
										<div className="pe_print_display">
											<div className="pe_print_logo">
                        <h3>ตรวจสุขภาพ</h3>
											</div>
											<div className="pe_sticker_column">
												{ this.state.patient.name }, { this.state.patient.age }<br/>
												{ this.state.patient.vn }<br/>
												(แปะสติ๊กเกอร์)
											</div>
											<div className="pe_print_content">
												<table className="pe_print_content_table">
													<tbody>
														<tr>
															<th width="15%">{translate('name')}</th>
															<td width="30%">{ this.state.patient.name }</td>
															<th width="5%">{translate('gender')}</th>
															<td width="5%">{ translate(''+this.state.patient.gender) }</td>
															<th width="13%">{translate('employee_id')}</th>
															<td width="8%">{ this.state.patient.eid }</td>
															<th width="5%">VN</th>
															<td width="10%">{ this.state.patient.vn }</td>
														</tr>
														<tr>
															<th>{translate('company_branch')}</th>
															<td>{ this.state.patient.company }</td>
															<th>{translate('department')}</th>
															<td colSpan="2">{ this.state.patient.department }</td>
															<th>{translate('position')}</th>
															<td colSpan="2">{ this.state.patient.position }</td>
														</tr>
													</tbody>
												</table>
												<h6>1. ยื่นเอกสารนี้ให้ เจ้าหน้าที่จุดชั่งน้ำหนัก</h6>
												<table className="pe_print_content_table">
													<tbody>
														<tr>
															<th width="13%">{translate('weight')}</th>
															<td width="20%">{ this.state.patient.weight }</td>
															<th width="13%">{translate('height')}</th>
															<td width="20%">{ this.state.patient.height }</td>
															<th width="13%">BMI</th>
															<td width="20%">{ this.state.patient.bmi }</td>
														</tr>
													</tbody>
												</table>
												<h6>2. ยื่นเอกสารนี้ให้ จุดวัดความดัน เพื่อซักประวัติ</h6>
												<table className="pe_print_content_table">
													<tbody>
														<tr>
															<th width="15%">{translate('birthday')}</th>
															<td width="20%">{ this.state.patient.dob }</td>
															<th width="10%">{translate('age')}</th>
															<td width="20%">{ this.state.patient.age }</td>
															<th width="13%">{translate('blood_type')}</th>
															<td width="20%">{ this.state.patient.blood_typ }</td>
														</tr>
													</tbody>
												</table>
												<table className="pe_print_content_table">
													{this.state.patient.visit && this.state.patient.visit.bp?
														<tbody>
														{this.state.patient.visit.bp.map((item,k)=>
															<tr key={k}>
																<th width="15%">{translate('blood_pressure')}</th>
																<td width="51%">{ item.sbp } / { item.dbp }</td>
																<th width="10%">{translate('pulse')}</th>
																<td width="20%">{ item.pulse }</td>
															</tr>
														)}
														</tbody>
													:
													<tbody>
														<tr>
															<th width="15">{translate('blood_pressure')}</th>
															<td width="51%">{ this.state.patient.dob }</td>
															<th width="10%">{translate('pulse')}</th>
															<td width="20%">{ this.state.patient.blood_typ }</td>
														</tr>
													</tbody>
													}
												</table>
												<div className="pe_list pe_list_column">
													{this.state.patient.personal && this.state.patient.personal.length>0?
													<div>
														{this.state.patient.personal.map((item,k)=>
														<div className="pe_list_item" key={k}>
															<div className="pe_list_title">{item[0]}</div>
															<div className="pe_list_check">
																<span>
																	{item[1]==="1"?
																		<i className="far fa-check-square"></i>
																	:
																		<i className="far fa-square"></i> 
																	}
                                  {item[3] && item[3]==='fasting'?
                                  <span>{translate('not_fast')}</span>
                                  : <span>{translate('not_have')}</span> }
																</span>
																<span>
																	{item[1]==="0"?
																		<i className="far fa-check-square"></i>
																	:
																		<i className="far fa-square"></i> 
																	}
                                  {item[3] && item[3]==='fasting'?
                                  <span>{translate('fast')}</span>
                                  : <span>{translate('have')}</span> }
																</span>
															</div>
															<div className="pe_list_result">{item[2]}</div>
														</div>
														)}
													</div>
													:null}
												</div>
												<h6>3. ยื่นเอกสารนี้ให้แพทย์</h6>
												<div className="">
													<div className="">
														{this.state.patient.pe.map((item,k)=>
														<div className="pe_list_item" key={k}>
															<div className="pe_list_title">{item[0]}</div>
															<div className="pe_list_check">
																<span>
																	{item[1]==="1"?
																		<i className="far fa-check-square"></i>
																	:
																		<i className="far fa-square"></i> 
																	}
																	{translate('normal')}
																</span>
																<span>
																	{item[1]==="0"?
																		<i className="far fa-check-square"></i>
																	:
																		<i className="far fa-square"></i> 
																	}
																	{translate('abnormal')}
																</span>
															</div>
															<div className="pe_list_result">{item[2]}</div>
														</div>
														)}
														{this.state.patient.fived?
														<div>
															{this.state.patient.fived.map((item,k)=>
															<div className="pe_list_item" key={k}>
																<div className="pe_list_title">{item[0]}</div>
																<div className="pe_list_check">
																	<span>
																		{item[1]==="1"?
																			<i className="far fa-check-square"></i>
																		:
																			<i className="far fa-square"></i> 
																		}
																		{translate('normal')}
																	</span>
																	<span>
																		{item[1]==="0"?
																			<i className="far fa-check-square"></i>
																		:
																			<i className="far fa-square"></i> 
																		}
																		{translate('abnormal')}
																	</span>
																</div>
																<div className="pe_list_result">{item[2]}</div>
															</div>
															)}
														</div>
														:null}
                            <br/>
														<p>{translate('note')}:</p>
														<table className="pe_print_content_table">
															<tbody>
																<tr><td>&nbsp;</td></tr>
																<tr><td>&nbsp;</td></tr>
															</tbody>
														</table>
														<div className="pe-print-signature">
															<span>แพทย์</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="pe-print-buttons">
											<h6>{translate('print_pe')}</h6>
											<p>
												<button className="btn-round btn-block" onClick={this.doPrint}>
													{translate("print_again")}
												</button>
											</p>
											<p>
												<button className="btn-round btn-black btn-block" onClick={this.goBack}>
													{translate("done")}
												</button>
											</p>
										</div>
									</div>
								) : (
									<Loading show={true} />
								)}
							</div>
							</div>
						</main>
					</div>
				}
			</Translate>
		);
	}
}

export default ManagementPEPrint;
