import React, { Component } from "react";

import Logout from '../../components/Logout';
import Loading from '../../components/Loading';

import authFetch from '../../services/authFetch';
import { Translate } from "react-localize-redux";

import {
	Nothing,
	RHX,
	VHX,
	Glasses,
	VisionTable,
	PerimeterScore,
	EXPY,
	EXPD,
	ExpOc,
	NoiseType,
	HearingHX,
	AudioTable,
	ProtectorTypeWrapper,
	Comment,
} from '../../components/OcForm';

class FormFactory {
	constructor(template, setState, type = 'vision') {
		if (template === null) {
			return null
		}
		if (type === 'vision' && Array.isArray(template)) {
			return new VisionTable(template, setState).render()
		}
		if (template.handle === 'vhx') {
			return <VHX template={template} setState={setState}></VHX>
		}
		if (template.handle === 'rhx') {
			return <RHX template={template} setState={setState}></RHX>
		}
		if (template.handle === 'glasses') {
			return <Glasses template={template} setState={setState}></Glasses>
		}
		if (template.handle === 'perimeter') {
			return <PerimeterScore template={template} setState={setState}></PerimeterScore>
		}

		// audio
		if (template.handle === 'expy') {
			return <EXPY template={template} setState={setState}></EXPY>
		}
		if (template.handle === 'expd') {
			return <EXPD template={template} setState={setState}></EXPD>
		}
		if (template.handle === 'exp_oc') {
			return <ExpOc template={template} setState={setState}></ExpOc>
		}
		if (template.handle === 'noise_type') {
			return <NoiseType template={template} setState={setState}></NoiseType>
		}
		if (template.handle === 'protector') {
			return <ProtectorTypeWrapper template={template} setState={setState}></ProtectorTypeWrapper>
		}
		if (template.handle === 'hearing_hx') {
			return <HearingHX template={template} setState={setState}></HearingHX>
		}
		if (template.handle === 'frequency') {
			return <AudioTable template={template} setState={setState}></AudioTable>
		}

		if (template.handle === 'comment') {
			return <Comment template={template} setState={setState}></Comment>
		}
		return new Nothing().render()
	}
}
class ManagementOCForm extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			record: this.props.match.params.record,
			keyword: this.props.match.params.vn,
			visit:null,
			show_modal: false,
			loading:true,
			oc_list:[],
			note: "",
			ocs:[],
			form: null,
		};
		
		this.doSubmit = this.doSubmit.bind(this);
		this.handleOcChange = this.handleOcChange.bind(this);
		
		this.handleOcsChange = this.handleOcsChange.bind(this);
		this.handleOcsNoteChange = this.handleOcsNoteChange.bind(this);
		
		this.afterSave = this.afterSave.bind(this);
		this.loadVisit = this.loadVisit.bind(this);
		this.goBack = this.goBack.bind(this);
		this.ctrlSubmit = this.ctrlSubmit.bind(this);
  }
  
  componentWillUnmount(){
    document.removeEventListener("keydown", this.ctrlSubmit, false);
  }
  
  ctrlSubmit(e) 
  {  
	  if (e.ctrlKey && e.keyCode == 13) {
	    // Ctrl-Enter pressed
		  this.doSubmit(e);
	  }
	  return;
  }
  
  componentDidMount() {
	  document.addEventListener("keydown", this.ctrlSubmit, false);
		authFetch.get(`${this.state.record}`, (result) => {
			if (result.success) {
				this.loadVisit(result.oc_templates);
			}
		})
  }
  
  loadVisit(oc_templates)
  {
		authFetch.get(`${this.state.record}/search?keyword=${this.state.keyword}`,(result)=>{
		  	if(result.success && result.visit){
				const ocs = oc_templates.map(x => {
					const item = result.visit.result.find(j => j.id === x.id)
					return {
						...x,
						value: item ? item.value : '',
						note: item ? item.note : '',
					}
				})
				this.setState({
					oc_list: ocs,
					visit: result.visit,
					form: oc_templates,
				})
			} else {
				this.setState({visit:[]});
			}
			this.setState({loading:false});
		});
  }
  
  handleOcChange(event)
  {
    const target = event.target;
    const value = (target.type === 'checkbox' || target.type === 'radio') ? target.value : target.value;
    const name = target.name;
    this.setState({oc:{...this.state.oc,...{[name]: value}}});
  }
  
  handleOcsChange(id,value)
  {
    var pes = [];
    this.state.pe_list.map((item,index)=>{
	    if(item.id===id) {
		    item.value=value;
	    }
	    pes.push(item);
    });
    this.setState({pe_list:pes});
  }
  
  handleOcsNoteChange(id,event)
  {
    var pes = [];
    this.state.pe_list.map((item,index)=>{
	    if(item.id===id) {
		    item.note=event.target.value;
	    }
	    pes.push(item);
    });
    this.setState({pe_list:pes});
  }
  doSubmit(e)
  {
		e.preventDefault();
		this.setState({loading:true});
		// const test = this.state.oc_list.filter(x => x.value.length !== 0)
		const test = this.state.oc_list.filter(x => !!x.value)
		// const test = this.state.oc_list.map(x => {
		// 	if (!!x.value) {
		// 	} else {
		// 		x.value = 'empty'
		// 	}
		// 	return x
		// })
		if (test.some(x => x.value === 'empty')) {
			this.setState({loading: false})
			window.doAlert("Please fill all fields.");
			return
		}
		authFetch.post(`${this.state.record}`, {
			vn: this.state.visit.vn,
			test,
		},(result)=>{
			this.setState({
				loading: false
			})
			this.afterSave(result)
		});
		
		return;
  }
  
  goBack()
  {
		var backTo = `/management/oc_search/${this.state.record}`;
		if(this.props.location.state && this.props.location.state.backTo) 
		{
			backTo = this.props.location.state.backTo;
		} 
		this.props.history.push(backTo);
  }
  
  afterSave(result)
	{
		if(result.success){
			this.goBack();
	  } else {
		  this.setState({loading:false});
		  if(result.errors)window.doAlert(result.errors.join('\n'))
		  else {
			  console.error(result);
			  window.doAlert("Server Response Error");
			}
	  }
	}

	getPerimeterScore(form) {
		const data = form.filter(x => x.handle.includes('peri_'))
		return {
			id: 'perimeter',
			handle: 'perimeter',
			name: 'Perimeter Score',
			data
		}
	}
	getFrequency(form) {
		const data = form.filter(x => x.handle.includes('rt') || x.handle.includes('lt'))
		return {
			id: 'frequency',
			handle: 'frequency',
			name: 'frequency',
			data
		}
	}
	getProtector(form) {
		const data = form.filter(x => x.handle.includes('protector'))
		return {
			id: 'protector',
			handle: 'protector',
			name: 'protector',
			data
		}
	}

	set(props, newState) {
		const oc = this.state.oc_list.find(x => x.id === props.template.id)
		switch(props.template.handle) {
			case 'vhx':
				const valueVHX = this.processVHX(newState.value.join(','), newState.note)
				oc.value = valueVHX ? valueVHX : 'ไม่มี'
				oc.note = newState.note
				break
			case 'rhx':
				const valueRHX = this.processVHX(newState.value.join(','), newState.note)
				oc.value = valueRHX ? valueRHX : 'ไม่มี'
				oc.note = newState.note
				break
			case 'perimeter':
				props.template.data.forEach(x => {
					const innerOc = this.state.oc_list.find(j => j.id === x.id)
					if (x.handle.includes('total')) {
						innerOc.value = newState[innerOc.handle]
					} else {
						innerOc.value = newState[innerOc.handle] ? '1': '0'
					}
				})
				break
			case 'frequency':
				props.template.data.forEach(x => {
					const innerOc = this.state.oc_list.find(j => j.id === x.id)
					innerOc.value = `${newState[innerOc.handle]}`
				})
				break
			default:
				oc.value = newState.value
				oc.note = newState.note
		}
	}

	// helper
	processVHX(value, note) {
		const isEmpty = (x) => !!x
		const filtered = value.split(',').filter(isEmpty)
		const otherIndex = filtered.indexOf('(อื่นๆ)')
		if (otherIndex !== -1) {
			filtered[otherIndex] = `(อื่นๆ) ${note}`
		}
		return filtered.join(',')
	}
	processRHX(value) {
		const isEmpty = (x) => !!x
		const filtered = value.split(',').filter(isEmpty)
		return filtered.join(',')
	}
  
  	render() {
		return (
			<Translate>
				{({ translate }) => 
					<div className="management-page page">
						<header className="page-header">
							<button className="link-page-back" onClick={this.goBack}>
								{translate("back")}
							</button>
							<h1>{translate("save_oc_result")}</h1>
							<Logout/>
						</header>
						
						<main className="oc-form-wrapper">
							<div className="oc-form-body">
								{
									this.state.record === 'vision' ?
										<>
											{this.state.form && new FormFactory(this.state.oc_list[0], this.set.bind(this))}
											<div className="oc-form-two-column">
												{this.state.form && new FormFactory(this.state.oc_list[1], this.set.bind(this))}
												{this.state.form && new FormFactory(this.state.oc_list[2], this.set.bind(this))}
											</div>
											{this.state.form && new FormFactory(this.state.oc_list, this.set.bind(this))}
											{this.state.form && new FormFactory(this.getPerimeterScore(this.state.oc_list), this.set.bind(this))}
										</>
									: null
								}
								{
									this.state.record === 'audio' ?
										<>
											{this.state.form && new FormFactory(this.state.oc_list[0], this.set.bind(this))}
											{this.state.form && new FormFactory(this.state.oc_list[1], this.set.bind(this))}
											<div className="oc-form-two-column">
												{this.state.form && new FormFactory(this.state.oc_list[2], this.set.bind(this))}
												{this.state.form && new FormFactory(this.state.oc_list[3], this.set.bind(this))}
											</div>
											{this.state.form && new FormFactory(this.getProtector(this.state.oc_list), this.set.bind(this))}
											{this.state.form && new FormFactory(this.state.oc_list[6], this.set.bind(this))}
											{this.state.form && new FormFactory(this.getFrequency(this.state.oc_list), this.set.bind(this))}
										</>
									: null
								}
								{this.state.form && new FormFactory(this.state.oc_list.find((e) => e.handle === 'comment'), this.set.bind(this))}
							</div>
							<div className="pe-form-footer">
								<div className="pe-form-footer-info">
									<p><strong>{translate("name")}</strong>: {this.state.visit && this.state.visit.fullname}</p>
									<p><strong>{translate("vn")}</strong>: {this.state.visit && this.state.visit.vn}</p>
								</div>
								<div className="pe-form-footer-button">
									<button className="btn-round btn-block" onClick={this.doSubmit}>{translate("save_data")}</button>
								</div>
							</div>
						</main>
						<Loading show={this.state.loading}/>
					</div>
				}
			</Translate>	
		)
	}
}

export default ManagementOCForm;
