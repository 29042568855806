import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';

import './App.scss';

import { LocalizeProvider } from "react-localize-redux";

import Auth from './helpers/Auth';
import InitApp from './helpers/InitApp';

import Login from './pages/auth/login';

import Home from './pages/home/home';

import Register from './pages/register/register';
import RegisterPrevious from './pages/register/previous';
import RegisterSticker from './pages/register/sticker';
import RegisterForm from './pages/register/form';
import RegisterSearch from './pages/register/search';

import Management from './pages/management/management';
import ManagementVisit from './pages/management/visits';
import ManagementPESearch from './pages/management/pe_search';
import ManagementPEForm from './pages/management/pe_form';
import ManagementPEPrint from './pages/management/pe_print';
import ManagementMCPrint from './pages/management/mc_print';

import ManagementOCSearch from './pages/management/oc_search';
import ManagementOCForm from './pages/management/oc_form';

import ManagementSpecimen from './pages/management/specimens';
import ManagementSpecimenAll from './pages/management/specimens_all';
import ManagementVisitSpecimen from './pages/management/visit_specimens';
import ManagementVisitStickers from './pages/management/visit_stickers';

import ManagementPatientSearch from './pages/management/patient_search';
import ManagementPatientInfo from './pages/management/patient_info';
import ManagementPrintPDF from './pages/management/print_pdf';

import SettingPage from './pages/home/setting';

function App() {
  return (
	  <LocalizeProvider>
		  <InitApp>
		  	<Router>
			  	<AnimatedSwitch
			      atEnter={{ opacity: 0 }}
			      atLeave={{ opacity: 0 }}
			      atActive={{ opacity: 1 }}
			      className="switch-wrapper" >
			      <Route exact path="/" render={() => <Redirect to="/home" />} />
			      <Route path="/login" component={Login} />
			      <PrivateRoute path="/home" component={Home} />
			      <PrivateRoute path="/register" component={RouteRegister} />
			      <PrivateRoute path="/management" component={RouteManagement} />
			      <PrivateRoute path="/setting" component={SettingPage} />
			    </AnimatedSwitch>
				</Router>
			</InitApp>
		</LocalizeProvider>
  );
}

function RouteRegister({ match }) {
  return (
  	<AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      className="switch-wrapper" >
      <Route exact path={match.path} component={Register} />
      <Route path={match.path+'/stickers/:vn?'} component={RegisterSticker} />
      <Route path={match.path+'/previous'} component={RegisterPrevious} />
      <Route path={match.path+'/form/:vn?'} component={RegisterForm} />
      <Route path={match.path+'/search/:keyword?'} component={RegisterSearch} />
    </AnimatedSwitch>
  );
}

function RouteManagement({ match }) {
  return (
  	<Switch
      //atEnter={{ opacity: 0 }}
      //atLeave={{ opacity: 0 }}
      //atActive={{ opacity: 1 }}
      className="switch-wrapper" >
      <Route exact path={match.path} component={Management} />
      <Route path={match.path+'/visits'} component={ManagementVisit} />
      <Route path={match.path+'/pe_search'} component={ManagementPESearch} />
      <Route path={match.path+'/pe_form/:vn'} component={ManagementPEForm} />
      <Route path={match.path+'/pe_print/:vn'} component={ManagementPEPrint} />
      <Route path={match.path+'/mc_print/:vn'} component={ManagementMCPrint} />
      <Route path={match.path+'/oc_search/:record'} component={ManagementOCSearch} />
      <Route path={match.path+'/oc_form/:record/:vn'} component={ManagementOCForm} />

      <Route path={match.path+'/specimens_all/:hilight?'} component={ManagementSpecimenAll} />
      <Route path={match.path+'/specimens'} component={ManagementSpecimen} />
      <Route path={match.path+'/visit/specimens/:vn'} component={ManagementVisitSpecimen} />
      <Route path={match.path+'/visit/stickers/:vn'} component={ManagementVisitStickers} />
      
      <Route path={match.path+'/patients'} component={ManagementPatientSearch} />
      <Route path={match.path+'/patient/:vn'} component={ManagementPatientInfo} />

      <Route path={match.path+'/printpdf'} component={ManagementPrintPDF} />
    </Switch>
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        Auth.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export default App;
