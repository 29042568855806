import React, { Component } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import globalTranslations from "../translations/global.json";

import authFetch from '../services/authFetch';
import Auth from './Auth';

import NativeCardReader from './NativeCardReader';

import { Translate } from "react-localize-redux";

class InitApp extends Component {
	constructor(props) {
    super(props);
    Auth.init();
    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Thai", code: "th" }
      ],
      translation: globalTranslations,
      options: { defaultLanguage: "th",renderToStaticMarkup }
    });
	
    this.state = {
	    connection: true,
	    alert: null,
	    confirm: null,
	    warning: null,
	    info: null
    }
    this.checkHeartBeat = this.checkHeartBeat.bind(this);
    
    window.doInfo = (e) => {
	    this.setState({info:e})
    }
    
    window.doAlert = (e) => {
	    this.setState({alert:e})
    }
    
    window.doConfirm = (text,ok,no) => {
	    //console.log(obj)
	    //console.log(e)
	    this.setState({confirm:{text:text,ok:ok,no:no}});
	    /*
    	$('#dialog').html('\
        <div>\
            <h2>Confirm</h2>\
            <p>Are you sure?</p>\
            <p>\
                <button name="confirm" value="yes" class="facebox-btn close">Yes</button>\
                <button name="confirm" value="no" class="facebox-btn close">No</button>\
            </p>\
        </div>').overlay().load();
			$('button[name=confirm]').click(function(){
        if ($(this).val() == 'yes') {
            $('#dialog').overlay().close();
            obj.data('confirmed', true).click().removeData('confirmed');
        } else {
            $('#dialog').overlay().close();
        }
    	});
    	*/
    }
  }
  
  componentDidMount() {
		// check connection
		/*
		setInterval(()=>{
			this.checkHeartBeat();
		}, 6000)
    */
   window.cardreader = new NativeCardReader();
    authFetch.get('config',(data)=>{
      console.log(data);
      if(data){
        window.app_config = {
          logo: data.logo,
          logo_bw: data.logo_bw,
          sticker: data.sticker
        }
      }
    });
  }
  
  checkHeartBeat()
  {	  
		var connection = false;
		authFetch.get('heartbeat',(data)=>{
		  if(data && data.success){
		    connection = true;
	    }
			this.setState({connection:connection});
	  });
  }
  
  
  render() {
    return (
		<Translate>
			{({ translate }) => 
				<div>
			  {this.props.children}
			  
			  {this.state.connection?null:<div className="no-connection-alert">Cannot connect to server. Please check your network connection.</div>}
			  
			  {this.state.alert?<div className="popup-alert-box"><span>{this.state.alert}<i onClick={()=>{this.setState({alert:null})}}>&times;</i><br/><br/><button className="btn btn-round-sm btn-primary" onClick={()=>{this.setState({alert:null})}}>OK</button></span></div>:null}
			  
			  {this.state.info?<div className="popup-info-box"><span>{this.state.info}<i onClick={()=>{this.setState({info:null})}}>&times;</i></span></div>:null}
			  
			  {this.state.confirm?(
				  <div className="popup-confirm-box">
					  <div>
						  <i onClick={()=>{this.setState({confirm:null})}}>&times;</i>
						  <p className="text-center">{translate(this.state.confirm.text)}</p>
							<p className="text-center">
								<button type="button" onClick={()=>{this.setState({confirm:null});this.state.confirm.no()}}>{translate('cancel')}</button>
								<button className="popup-confirm-btn" type="button" onClick={()=>{this.setState({confirm:null});this.state.confirm.ok()}}>{translate('ok')}</button>
							</p>
						</div>
					</div>):null}
				</div>
			}
		</Translate>
	);
  }
}

export default withLocalize(InitApp);