import React, { Component } from "react";
import {
  Link,
} from "react-router-dom";

import Logout from '../../components/Logout';
import InlineLoading from '../../components/InlineLoading';
import MiniLoading from '../../components/MiniLoading';
import ProgressOverview from '../../components/ProgressOverview';

import SortArrow from '../../components/SortArrow';
import Loading from '../../components/Loading';

import authFetch from '../../services/authFetch';
import { Translate } from "react-localize-redux";

import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

class ManagementSpecimenAll extends Component {
	constructor(props) {
    super(props);
    this.state = {
	    specimens: [],
	    visits: [],
	    data: [],
	    oriData: [],
	    sortDesc: true,
	    sort:""
    };
    
    this.buildData = this.buildData.bind(this);
    this.compareBy = this.compareBy.bind(this);
    this.sortBy = this.sortBy.bind(this);
    this.initSort = this.initSort.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
    this.renderRow = this.renderRow.bind(this);
    
  }
    
  componentDidMount() {
	  
  	authFetch.get('specimens',(result)=>{
		  if(result.success){
			  //console.log(result);
			  this.setState({specimens:result.specimens},this.buildData);
	    }
	  });
	  
  	authFetch.get('visits/count',(result)=>{
		  if(result.success){
			  //console.log(result);
			  var visits = result.visits.map((item)=>{
				  item.specimens = (item.specimens)?item.specimens.split(','):[];
				  item.specimens_received = (item.specimens_received)?item.specimens_received.split(','):[];
				  return item;
			  })
			  this.setState({visits:visits},this.buildData);
	    }
	  }); 
  }
  
  buildData()
  {
	  if(this.state.visits && this.state.specimens)
	  {
		  var data = [];
		  this.state.visits.map((visit,index)=>{
			  var sp = {};
			  this.state.specimens.map((item,sindex)=>{
				  if(visit.specimens && visit.specimens.includes(item.id)) {
					  // visit has specimen
					  if(visit.specimens_received && visit.specimens_received.includes(item.id)){
					  	// has and already received
					  	sp = {...sp,...{['sp_'+item.id]:2}}
					  } else {
						  // has but haven't reveived
					  	sp = {...sp,...{['sp_'+item.id]:1}}
					  }
				  } else {
					  // visit dont't have specimen
					  	sp = {...sp,...{['sp_'+item.id]:0}}
				  }
			  }) // end loop specimens
			  data.push({...visit,...sp})
		  }) // end loop visits
		  this.setState({oriData:data,data:data},this.initSort);
	  }
  }
  
  initSort()
  {
	  if(this.props.match.params.hilight) {
		  var sort  = 'sp_'+this.props.match.params.hilight;
		  this.setState({hilight:sort},this.sortBy(sort));
	  }
	}
  
  compareBy(key,sortDesc) {
    return function (a, b) {
      a[key] = (a[key]===null) ? "":a[key];
      b[key] = (b[key]===null) ? "":b[key];
	    if(sortDesc)
	    {			    
	      if (a[key] < b[key]) return -1;
	      if (a[key] > b[key]) return 1;
	    } else {
	      if (a[key] < b[key]) return 1;
	      if (a[key] > b[key]) return -1;
	    }
      return 0;
    };
  }
 
  sortBy(key) {
	  var sortDesc = this.state.sortDesc;
    let arrayCopy = [...this.state.data];
    if(key===this.state.sort)sortDesc=!sortDesc;
    arrayCopy.sort(this.compareBy(key,sortDesc));
    this.setState({data: arrayCopy,sortDesc:sortDesc,sort:key,hilight:key});
  }
  
  filterSearch(e)
  {
	  var value = e.target.value;
	  if(value){
		  var data =  this.state.oriData.filter(function(item) {
				return (item.vn.indexOf(value) !== -1 || item.fullname.indexOf(value) !== -1);
			});
	  }else{
		  var data =  this.state.oriData
	  }
	  this.setState({data:data},()=>{this.sortBy(this.state.sort)})
  }
  
  gotoEdit(vn)
  {
	  this.props.history.push('/management/visit/specimens/'+vn)
  }

  getTime(date) {
    if(!date)return "";
    var time = new Date(date);
		var current = (
    	("0" + time.getHours()).slice(-2)   + ":" + 
			("0" + time.getMinutes()).slice(-2)
    );
    return current;
  }

  renderRow({ index, style }) {
    let visit = this.state.data[index];
    return (
      <div key={visit.vn} style={style} className={"speciments-row "+((index%2)?'':'odd')}>
        <div className="speciments-row-vn">{visit.vn}</div>
        <div className="speciments-row-name">{visit.fullname}</div>
        <div className="speciments-row-time">{this.getTime(visit.registered_time)}</div>
        {this.state.specimens.map((item)=>{
          return <div key={"sp-"+item.id} className={"speciments-row-checkbox"}>
            {visit.specimens && visit.specimens.includes(item.id)?
              (<div>
                {visit.specimens_received && visit.specimens_received.includes(item.id)?(
                  <button className="mini-check">
                    <i className="fa fa-check"></i>
                  </button>
                ):(
                  <button className="mini-check">
                    <i></i>
                  </button>
                )}
              </div>
              ) : (<i></i>)
            }
          </div>
        })}
        <div className="speciments-row-checkbox"><i className="fa fa-edit" onClick={()=>{this.gotoEdit(visit.vn)}}></i></div>
      </div>
    );
  }

  render() {
    return (
			<Translate>
			{({ translate }) => 
	      <div className="management-page page">
	      	<header className="page-header">
		      	<Link className="link-page-back" to={'/management'}>
	      			{translate("back")}
	      		</Link>
		      	<h1>{translate("count_specimen")}</h1>
		      	<Logout/>
	      	</header>
	      	<main className="page-content">
		      	<div className="page-content-main">
			      	<div className="page-content-header">
				      	<div>
					      	<div className="search-form">
			      				<input type="text" className="form-control" placeholder={translate("search")} onChange={this.filterSearch} autoFocus />
			      				<button type="button" className="search-form-btn"><i className="fa fa-search"></i></button>
		      				</div>
					      </div>
					      <div></div>
				      	<div className="text-right">
					      	<Link className="btn btn-link" to={'/management'}>
				      			{translate("close_page")} <img src={require('assets/images/icon-minimize.svg')} width="30" />
			      			</Link>
			      		</div>
		    			</div>
		      		{(this.state.specimens.length>0 && this.state.visits.length>0)?
			      		(
                  <div className="specimens-list">
                    <div className="specimens-list-header">
                      <div className="speciments-row-vn" onClick={()=>{this.sortBy('vn')}}>
                        {this.state.sort==="vn"?(<SortArrow desc={this.state.sortDesc}/>):(null)}
                        {translate('vn')}
                      </div>
                      <div className="speciments-row-name" onClick={()=>{this.sortBy('fullname')}}>
                        {this.state.sort==="fullname"?(<SortArrow desc={this.state.sortDesc}/>):(null)}
                        {translate('name')}
                      </div>
                      <div className="speciments-row-time" onClick={()=>{this.sortBy('registered_time')}}>
                        {this.state.sort==="registered_time"?(<SortArrow desc={this.state.sortDesc}/>):(null)}
                        REGIS
                      </div>
                      {this.state.specimens.map((item,index)=>{
                        return <div key={"th-"+index} className={"speciments-row-checkbox "+(this.state.hilight=='sp_'+item.id?"light-table-hilight":"")}
                          onClick={()=>{this.sortBy('sp_'+item.id)}}>
                          {this.state.sort==="sp_"+item.id?(<SortArrow desc={this.state.sortDesc}/>):(null)}
                          <span><small>{item.name}</small></span>
                        </div>
                      })}
                      <div className="speciments-row-checkbox"></div>
                    </div>
                    <div className="specimens-list-data">
                    <AutoSizer>
                    {({ height, width }) => (
                      <List height={height} width={width} itemSize={30} itemCount={this.state.data.length} sortBy={this.state.sort} sortDirection={this.state.sortDesc}>
                        {this.renderRow}
                      </List>
                    )}
                    </AutoSizer>
                    </div>
                  </div>
			      		) : (
						      <Loading show={true} />
								)
							}
						</div>
		      </main>
	      </div>
      }
			</Translate>
    );
  }
}

export default ManagementSpecimenAll;
