import React, { Component } from "react";
import Barcode from 'react-barcode';

import InlineLoading from '../../components/InlineLoading';

import { Translate } from "react-localize-redux";
import Logout from "../../components/Logout";


class ManagementPrintPDF extends Component {
	constructor(props) {
    super(props);
    this.state = {
	    pes: null
    };
    this.doPrint = this.doPrint.bind(this);
    this.cancelPrint = this.cancelPrint.bind(this);
    this.donePrint = this.donePrint.bind(this);
    
    let printStyle = document.getElementById('specific-print');
    let style = document.createTextNode('@page { size: A5 landscape; margin:1cm;padding:0; }')
    printStyle.appendChild(style)

    if(window.detail) {
        this.state.detail = window.detail;
        console.log(this.state.detail)
	  } else {
		  window.close();
	  }
	  
	  //console.log(this.state.pes)
  }
  
  doPrint()
  {
	  window.print();
  }
  
  cancelPrint()
  {
	  window.cancelPrint();
  }
  
  donePrint()
  {
	  window.donePrint();
  }
    
  componentDidMount() {
	  //this.doPrint();
  }

  render() {
    return (
		<Translate>
			{({ translate }) => 
	        <div className="register-pe">
      		    {this.state.detail!==null  ? (
	      		<div className="pe-print-wrapper">
                    <div className="pe_print_display">
                        <div className="pe_print_column">
                            <img src={require('assets/images/logo.jpg')} />
                        </div>
                    </div>
	      			<div className="pe-print-buttons">
		      			<h6>{translate('print_pe')}</h6>
					      <p>
						      <button className="btn-round btn-block" onClick={this.doPrint}>
			      				{translate("print_again")}
		      				</button>
	      				</p>
	      				<br/>
	      				<p>
						      <button className="btn-round btn-block" onClick={this.cancelPrint}>
			      				{translate("cancel_print")}
		      				</button>
	      				</p>
	      				<p>
						      <button className="btn-round btn-black btn-block" onClick={this.donePrint}>
			      				{translate("done_print")}
		      				</button>
	      				</p>
      				</div>
      			</div>
			  	):(
			  		<InlineLoading show={true}/>
			  	)}
	      </div>
      }
			</Translate>
    );
  }
}

export default ManagementPrintPDF;
