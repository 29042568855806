import React, { Component } from "react";
import {
  Link,
} from "react-router-dom";

import MainLogo from '../../components/MainLogo';
import Logout from '../../components/Logout';

import { Translate } from "react-localize-redux";

class Setting extends Component {
	constructor(props) {
    super(props);
    
    var sticker_printer = localStorage.getItem('sticker_printer');
    if(!sticker_printer)
    {
	    sticker_printer = "zebra"
    }
    
    this.state = {
	    sticker_printer: sticker_printer
    };
    
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveSetting = this.saveSetting.bind(this);
  }
  
  handleInputChange(e)
  {
	  var target = e.target;
    var value = (target.type === 'checkbox' || target.type === 'radio') ? target.value : target.value;
    var name = target.name;
    this.setState({[name]: value});
  }
  
  saveSetting()
  {
	  localStorage.setItem('sticker_printer',this.state.sticker_printer);
	  alert("Saved");
	  this.props.history.push('/home');
  }

  render() {
    return (
			<Translate>
			{({ translate }) => 
	      <div className="setting-page">
		      <MainLogo/>
		      <Logout />
		      <div className="page-content-padded">
			      <h1>{translate("system_setting")}</h1>
			      <table className="light-table">
				      <tbody>
					      <tr>
						      <th width="200" className="text-left">{translate("sticker_printer")}</th>
							    <td>
								    <select className="form-control" onChange={this.handleInputChange} name="sticker_printer" value={this.state.sticker_printer}>
									    <option value="zebra">Zebra</option>
									    <option value="other">Other Printer</option>
									    <option value="none">None</option>
								    </select>
							    </td>
					      </tr>
					      { /*
					      <tr>
						      <th className="text-left">{translate("receipt_printer")}</th>
							    <td>
								    <select className="form-control">
									    <option value="thermal">Thermal Printer width = 80cm</option>
									    <option value="other">Other Printer</option>
									    <option value="none">None</option>
								    </select>
							    </td>
					      </tr>
					      */ }
				      </tbody>
				    </table>
				    <br/>
				    <p className="text-right"><button className="btn btn-round" onClick={this.saveSetting}>Save</button></p>
		      </div>
	      </div>
      }
			</Translate>
    );
  }
}

export default Setting;
