let printer;
let isNative = false;
if (typeof (window.printer) !== "undefined") {
  isNative = true;
  printer = window.printer; // eslint-disable-line no-undef
}

function nativePrint(stickers, cb) {
	stickers.reverse()
	var sticker_printer = localStorage.getItem('sticker_printer');
	if (!sticker_printer) sticker_printer = "zebra"

	if (sticker_printer == "zebra") {
		zebraPrint(stickers, cb);
	} else if (sticker_printer == "other") {
		normalPrint(stickers, cb);
	} else {
		cb(false);
	}
}

function zebraPrint(stickers, cb) {
	var device = null;
	var zpls = [];
	var topMargin = 5;
	var leftMargin = 12;
	var i = 0;
  //let today = new Date();
	stickers.map((item, index) => {
    // 5x2.5cm
    if(window.app_config.sticker==="5x25") {
      zpls.push("^XA^CI28^A@N,35,32,E:ANG000.FNT^FO"
      + leftMargin + "," + (topMargin + 3) + "^FH^FD" + item.fullname 
      + "^FS^A0N,30,30^FO"
      + (leftMargin + 305) + "," + (topMargin+5) + "^FH^FD" + item.no 
      + "^FS^A0R,28,25^FO"
      + (leftMargin + 345) + "," + (topMargin+55) + "^FH^FD" + item.registered_date
      + "^FS^A@N,30,30,E:ANG000.FNT^FO"
      + leftMargin + "," + (topMargin + 35) + "^FH^FD" + (item.company_name ? item.company_name : "-") 
      + "^FS^A@N,30,30,E:ANG000.FNT^FO"
      + leftMargin + "," + (topMargin + 57) + "^FH^FD" + (item.department ? item.department : "-") 
      + "^FS^A@N,40,35,E:ANG000.FNT^FO"
      + leftMargin + "," + (topMargin + 82) + "^FH^FD" + item.name 
      + "^FS^BY2,0,40^FO"
      + (leftMargin+30) + "," + (topMargin + 122) + "^BC^FD" + item.id + "^FS^XZ");
    } else {
      // 4x2cm
      zpls.push("^XA^CI28^A@N,32,27,E:ANG000.FNT^FO"
        + leftMargin + "," + (topMargin + 3) + "^FH^FD" + item.fullname + "^FS^A@N,40,40,E:ANG000.FNT^FO"
        + (leftMargin + 240) + "," + (topMargin) + "^FH^FD" + item.no + "^FS^A@N,25,25,E:ANG000.FNT^FO"
        + leftMargin + "," + (topMargin + 32) + "^FH^FD" + (item.company_name ? item.company_name : "-") + "^FS^A@N,30,30,E:ANG000.FNT^FO"
        + leftMargin + "," + (topMargin + 52) + "^FH^FD" + item.name + "^FS^BY2,0,40^FO"
        + leftMargin + "," + (topMargin + 80) + "^BC^FD" + item.id + "^FS^XZ");
    }
	});

	if(isNative) {
		print();
	}

	function print() {
		var string = "";
		zpls.map((item, index) => {
			string += item;
		});

		var encoder = new TextEncoder();
		var data = encoder.encode(string);

		//console.log(printer.getPrinters());
		printer.printDirect({
			data: data,
			printer: "Sticker",
			type: "RAW",
			success: () => {
				console.log("printed: " + data);
				printSuccess();
			},
			error: (err) => {
				gotError(err);
			}
		});
	}

	function printSuccess() {
		//console.log('success');
		cb(true, stickers)
	}

	function gotError(error) {
		//console.log(error);
		cb(false)
	}
}

function normalPrint(stickers, cb) {
	var printWindow = window.open('/register/stickers');
	printWindow.stickers = stickers;

	printWindow.donePrint = () => {
		printWindow.close();
		cb(true, stickers);
	};

	printWindow.cancelPrint = () => {
		printWindow.close();
		cb(false);
	};
}

export default nativePrint;