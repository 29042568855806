import React from "react"
import { Translate } from "react-localize-redux";
import { TextBox } from "../helper";

export class AudioTable extends TextBox {
    state = {
        rt500: '',
        rt1000: '',
        rt2000: '',
        rt3000: '',
        rt4000: '',
        rt6000: '',
        rt8000: '',
        lt500: '',
        lt1000: '',
        lt2000: '',
        lt3000: '',
        lt4000: '',
        lt6000: '',
        lt8000: ''
    }
    parentSetState = (any) => {};
    rightFrequency = [];
    leftFrequency = [];
    constructor(props) {
        super(props)
        this.parentSetState = props.setState;
        this.rightFrequency = props.template.data.filter(x => x.handle.includes('rt') && x.handle !== 'rt5000')
        this.leftFrequency = props.template.data.filter(x => x.handle.includes('lt') && x.handle !== 'lt5000')
    }
    componentDidMount() {
        const newState = {}
        this.props.template.data
            .forEach(x => {
                newState[x.handle] = x.value
            })
        this.setState(newState)
    }
    onChangeHandler(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        }, () => this.parentSetState(this.props, this.state));
    }
    render() {
        return (
            <Translate key={this.props.id}>
                {({ translate }) => (
                    <div className="oc-form-group-wrapper">
                        <table className="oc-form-table audio">
                            <thead>
                                <tr>
                                    <th>
                                        {translate('form_audio_table_frequency_header')}
                                    </th>
                                    <th>500</th>
                                    <th>1000</th>
                                    <th>2000</th>
                                    <th>3000</th>
                                    <th className="border-left">4000</th>
                                    <th>6000</th>
                                    <th>8000</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    > R - หูขวา (dB)</td>
                                    {
                                        this.rightFrequency.map(x => {
                                            return (
                                                <td
                                                    key={x.id}
                                                    className={`${x.handle === 'rt4000' ? 'border-left': ''}`}
                                                >
                                                    <label>
                                                        &nbsp;
                                                        <input
                                                            name={x.handle}
                                                            pattern="[0-9]*"
                                                            type="text"
                                                            inputMode="numeric"
                                                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                                                            maxLength="2"
                                                            value={this.state[x.handle]}
                                                            onChange={(e) => this.onChangeHandler(e)}
                                                        />
                                                    </label>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    > L - หูซ้าย (dB)</td>
                                    {
                                        this.leftFrequency.map(x => {
                                            return (
                                                <td
                                                    key={x.id}
                                                    className={`${x.handle === 'lt4000' ? 'border-left': ''}`}
                                                >
                                                    <label>
                                                        &nbsp;
                                                        <input
                                                            name={x.handle}
                                                            pattern="[0-9]*"
                                                            type="text"
                                                            inputMode="numeric"
                                                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                                                            maxLength="2"
                                                            value={this.state[x.handle]}
                                                            onChange={(e) => this.onChangeHandler(e)}
                                                        />
                                                    </label>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </Translate>
        )
    }
}