import React, { Component } from "react";
import {
	Link,
} from "react-router-dom";

import MainLogo from '../../components/MainLogo';
import Logout from '../../components/Logout';

import { Translate } from "react-localize-redux";

class Home extends Component {
	constructor(props) {
		super(props);
    this.state = {
      unit_name: null
    };
    
  }
  
  componentDidMount() {
    this.setState({
      unit_name:localStorage.getItem('unit_name')
    })
  }


	render() {
		return (
			<Translate>
				{({ translate }) =>
					<div className="home-page">
						<MainLogo />
						<Logout hideHome={true} />
						<div>
							<h1>{translate("choose_station")}</h1>
							<ul className="select-station">
								<li>
									<Link to={'/register'}>
										<span className="select-station-logo">
											<img alt="" src={require('assets/images/icon-register.svg')} />
										</span>
										{translate("register")}
									</Link>
								</li>
								<li>
									<Link to={'/management/pe_search'}>
										<span className="select-station-logo">
											<i className="fa fa-file-medical-alt"></i>
										</span>
										{translate("pe_result")}
									</Link>
								</li>
								<li>
									<Link to={'/management'}>
										<span className="select-station-logo">
											<img alt="" src={require('assets/images/icon-manage.svg')} />
										</span>
										{translate("management")}
									</Link>
								</li>
							</ul>
              <hr/>
              <p className="text-center"><small>{this.state.unit_name}</small></p>
						</div>
					</div>
				}
			</Translate>
		);
	}
}

export default Home;
