import React from "react"
import { RadioBox } from "./helper";
import { Translate } from "react-localize-redux";

const ans = [
    'R',
    'L',
    'T',
    'R',
    'B',
    'R',
    'T',
    'L',
    'T',
    'L',
    'B',
    'R',
    'B',
    'L',
]
export class NearBothEyes extends RadioBox {
    constructor(props) {
        super(props, props.setState)
    }
	render() {
		return (
			<Translate key={this.props.template.id}>
                {({ translate }) => 
                    <>
                        <tr>
                            <td colSpan="2">
                                9. {this.props.template.name}
                            </td>
                            {
                                ans.map((x, index) => 
                                    <td
                                        key={index}
                                        className={`
                                            ${index+1 <= 7 ? "highlight": ''}
                                            ${index+1 === 10 ? "border-left": ''}
                                        `.trim()}
                                    >
                                        <label
                                            className={this.state.value === `${index+1}` ? 'active': ''}
                                        >
                                            <input
                                                name="near-both-eyes"
                                                type="radio"
                                                value={`${index+1}`}
                                                onChange={(e) => this.onChangeHandler(e)}
                                                onClick={e => this.onClickHandler(e)}
                                                checked={this.state.value === `${index+1}`}
                                            />
                                            {x}
                                        </label>
                                    </td>
                                )
                            }
                            <td></td>
                        </tr>
                    </>
				}
			</Translate>
		)
	}
}