import React, { Component } from "react";
import {
	Link,
} from "react-router-dom";

import authFetch from '../../services/authFetch';

import Loading from '../../components/Loading';
import Logout from '../../components/Logout';
import InlineLoading from '../../components/InlineLoading';
import CardReader from '../../components/CardReader';
import Modal from '../../components/Modal';

import { Translate } from "react-localize-redux";

class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			previous: null,
			keyword: "",
			loading: false,
			askCreate: null
		};

		this.doSearch = this.doSearch.bind(this);
		this.updateKeyword = this.updateKeyword.bind(this);
		this.updateFromCard = this.updateFromCard.bind(this);
		this.captureBarcode = this.captureBarcode.bind(this);
		this.goToPatientForm = this.goToPatientForm.bind(this);
	}

	componentDidMount() {
		authFetch.get('visits/recent', (result) => {
			if (result.success) {
				//console.log(result.visits)
				this.setState({ previous: result.visits });
			}
		});
		document.addEventListener("keydown", this.captureBarcode, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.captureBarcode, false);
	}

	captureBarcode(e) {
		this.barcodeInput.focus();
		return;
	}

	updateKeyword(event) {
		const target = event.target;
		this.setState({ keyword: target.value });
	}

	doSearch(e) {
		e.preventDefault();
		var keyword = this.state.keyword;
		if (keyword) {
			this.setState({ loading: true });
			authFetch.get('visits/search?keyword=' + keyword, (result) => {
				if (result.success && result.visits.length > 0) {
					if (result.visits.length === 1) {
						this.props.history.push('/register/form/' + result.visits[0].vn);
					} else {
						this.props.history.push('/register/search/' + keyword);
					}
				} else {
					window.doAlert('Not found');
				}
				this.setState({ loading: false });
			});
		}

		return false;
	}

	updateFromCard(data) {
		var keyword = data.fullname;
		if (data.cid || data.fullname) {
			this.setState({ loading: true });
			authFetch.get('visits/search-id?fullname=' + data.fullname + "&cid=" + data.cid, (result) => {
				console.log(result);
				if (result.success && result.visits.length > 0) {
					if (result.visits.length === 1 && result.exact === 1) {
						this.goToPatientForm(result.visits[0].vn, data)
						//this.props.history.push('/register/form/'+result.visits[0].vn, { reader_data: data });
					} else {
						//window.doAlert("Found more than 1 patient with same Name or CID");
						this.props.history.push('/register/search/' + result.keyword, { reader_data: data });
					}
				} else {
					this.setState({ askCreate: data });
				}
				this.setState({ loading: false });
			});
		}
	}

	goToPatientForm(id, data) {
		this.props.history.push('/register/form/' + id, { reader_data: data });
	}

	render() {

		return (
			<Translate>
				{({ translate }) =>
					<div className="register-page page">
						<header className="page-header">
							<Link className="link-page-back" to={'/home'}>
								{translate("back")}
							</Link>
							<h1>{translate("register_and_edit")}</h1>
							<Logout />
						</header>

						<main className="page-content">
							{this.state.askCreate ? (
								<Modal show={true} title={translate("patient_not_found")}>
									<div>
										<p className="text-center">({this.state.askCreate.fullname})</p>
										<p className="text-center"><strong>{translate("do_you_want_to_create_new_patient")}</strong></p>
										<button className="btn-round" type="button"
											onClick={() => { this.setState({ askCreate: null }) }}>{translate("cancel")}</button>
										<span className="spacer" />
										<button type="button" className="btn-round btn-primary"
											onClick={() => { this.goToPatientForm("", this.state.askCreate) }}>{translate("create_new_patient")}</button>
									</div>
								</Modal>
							) : null}
							<div className="page-content-main">
								<div className="page-content-padded">
									<div className="action-box">
										<div className="action-box-image">
											<img alt="" src={require('assets/images/icon-card.svg')} />
										</div>
										<div className="action-box-content">
											<p><strong>{translate("read_citizen_card")}</strong></p>
											<CardReader autoRead={true} update={this.updateFromCard} />
										</div>
									</div>

									<p className="text-center"><strong>{translate("or")}</strong></p>

									<div className="action-box">
										<div className="action-box-image">
											<img alt="" src={require('assets/images/icon-search.svg')} />
										</div>
										<div className="action-box-content">
											<p><strong>{translate("search_database")}</strong> <small>({translate("type_name_cid_eid")})</small></p>

											<form onSubmit={(e) => this.doSearch(e)} className="search-form">
												<input type="text" className="form-control" placeholder={translate("search")} onChange={this.updateKeyword} ref={(input) => { this.barcodeInput = input; }} autoFocus />
												<button type="submit" className="search-form-btn"><i className="fa fa-search"></i></button>
											</form>
										</div>
									</div>

									<p className="text-center"><strong>{translate("or")}</strong></p>

									<Link to={'/register/form'} className="btn-round btn-block">
										<img alt="" src={require('assets/images/icon-plus.svg')} className="btn-icon-image" />
										{translate("add_patient")}
									</Link>
								</div>
							</div>

							<div className="page-content-aside">
								<div className="page-content-aside-header">
									<div className="page-content-aside-header-title">{translate("latest_registered")}</div>
									<div className="page-content-aside-header-button">
										<Link to={'/register/previous'}>
											<img alt="" src={require('assets/images/icon-expand.svg')} width="30" />
										</Link>
									</div>
								</div>

								<aside className="page-content-aside-content">
									{this.state.previous !== null ? (
										<table className="page-content-aside-table">
											<thead>
												<tr>
													<th className="text-left">{translate("name")}</th>
													<th className="text-center">{translate("vn")}</th>
													<th className="text-center" width="30"></th>
												</tr>
											</thead>
											<tbody>
												{this.state.previous.map(function (item, index) {
													return (<tr key={index}>
														<td className="text-left">{item.fullname}</td>
														<td className="text-center">{item.vn}</td>
														<td className="text-center">
															<Link to={'/register/form/' + item.vn}>
																<i className="fa fa-edit"></i>
															</Link>
														</td>
													</tr>)
												})}
											</tbody>
										</table>
									) : (
											<InlineLoading show={true} />
										)}
								</aside>

							</div>
						</main>
						<Loading show={this.state.loading} />
					</div>
				}
			</Translate>
		);
	}
}

export default Register;
