import React, { Component } from "react";

import Logout from '../../components/Logout';
import Loading from '../../components/Loading';
import Auth from '../../helpers/Auth';
import authFetch from '../../services/authFetch';
import { Translate } from "react-localize-redux";

class ManagementPEForm extends Component {
	constructor(props) {
    super(props);
    
    this.state = {
	    keyword: this.props.match.params.vn,
	    visit:null,
	    show_modal: false,
	    loading:true,
	    pe_list:[],
	    personal_list:[],
      practitioners: [],
      practitioner_id: null,
	    pe:{
		   wt:"",
		   ht:"",
	    },
	    note: "",
	    pes:[],
	    bp: [{sbp:"",dbp:"",pulse:""}],
    };
    
    this.addBp = this.addBp.bind(this);
    this.removeBp = this.removeBp.bind(this);
    this.doSubmit = this.doSubmit.bind(this);
    this.handlePeChange = this.handlePeChange.bind(this);
    this.handleBpChange = this.handleBpChange.bind(this);
    
    this.handlePesChange = this.handlePesChange.bind(this);
    this.handlePesNoteChange = this.handlePesNoteChange.bind(this);
    this.handlePersonalsChange = this.handlePersonalsChange.bind(this);
    this.handlePersonalsNoteChange = this.handlePersonalsNoteChange.bind(this);
    this.handlePractitionerChange = this.handlePractitionerChange.bind(this);
    
    this.afterSave = this.afterSave.bind(this);
    this.loadVisit = this.loadVisit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.ctrlSubmit = this.ctrlSubmit.bind(this);
  }
  
  componentWillUnmount(){
    document.removeEventListener("keydown", this.ctrlSubmit, false);
  }
  
  ctrlSubmit(e) 
  {  
	  if (e.ctrlKey && e.keyCode == 13) {
	    // Ctrl-Enter pressed
		  this.doSubmit(e);
	  }
	  return;
  }
  
  componentDidMount() {
	  document.addEventListener("keydown", this.ctrlSubmit, false);
	  authFetch.get('pe',(result)=>{
		  //console.log(result)
		  if(result.success){
			  this.loadVisit(result.pe_templates, result.personal_templates,result.practitioners);
			}
		});
  }
  
  loadVisit(pe_templates,personal_templates,practitioner_templates)
  {
		authFetch.get('bp/search?keyword='+this.state.keyword,(result)=>{
		  if(result.success && result.visit){
			  //console.log(result)
			  var pes = [];
			  pe_templates.map((item,index)=>{
				  result.visit.pe.map((vitem,vindex)=>{
					  if( item.id === vitem.id )
					  {
							item.value=vitem.value;
							item.note=vitem.note;
              if(item.value) {
                item.choices.map((choice)=>{
                  if(item.value===choice.value) {
                    item.show_text_input = choice.has_text_input
                  }
                });
              }
					  }
          });

          if(item.value===undefined) {
            item.choices.map((choice)=>{
              if(choice.is_default) {
                item.value = choice.value
                item.show_text_input = choice.has_text_input
              }
            });
          }
          if(item.value===undefined) {
            item.value="1";
            item.show_text_input = 0;
          }
				  if(!item.note)item.note="";
				  pes.push(item)
			  });
			  
			  var wt = null;
			  var ht = null;
			  var temp = null;
			  var personals = [];
			  personal_templates.map((item,index)=>{
				  result.visit.personal.map((vitem,vindex)=>{
					  if( item.id === vitem.id )
					  {
							item.value=vitem.value;
							item.note=vitem.note;
							
							if(item.handle==='wt')wt=vitem.value;
						  if(item.handle==='ht')ht=vitem.value;
						  if(item.handle==='temp')temp=vitem.value;
					  }
          });
          //console.log(item)
          if(item.value===undefined && item.handle==="fasting")item.value="0";
          else if(item.value===undefined)item.value="1";
				  //if(!item.note)item.note="";
				  personals.push(item)
			  });

        let practitioner_id = result.visit.practitioner_id;
        if(!practitioner_id) {
          if(Auth.user_role === 'doctor') {
            practitioner_id = Auth.user_id;
          }
        }
        
			  var set = {
				  pe: {
					  wt: wt,
					  ht: ht,
					  temp: temp
				  },
				  bp: result.visit.bp,
				  visit: result.visit,
				  pe_list: pes,
          practitioners: practitioner_templates,
				  personal_list: personals,
          practitioner_id: practitioner_id
			  }
			  if(set.bp.length===0)set.bp = [{sbp:"",dbp:"",pulse:""}];
        console.log(set)
			  this.setState(set);
			} else {
				this.setState({visit:[]});
			}
			this.setState({loading:false});
		});
  }

  handlePractitionerChange(event) {
    const target = event.target;
    const value = (target.type === 'checkbox' || target.type === 'radio') ? target.value : target.value;
    this.setState({practitioner_id: value});
  }
  
  handlePeChange(event)
  {
    const target = event.target;
    const value = (target.type === 'checkbox' || target.type === 'radio') ? target.value : target.value;
    const name = target.name;
    this.setState({pe:{...this.state.pe,...{[name]: value}}});
  }
  
  handleBpChange(index,event)
  {
	  const target = event.target;
    const value = (target.type === 'checkbox' || target.type === 'radio') ? target.value : target.value;
    const name = target.name;
    var bps = this.state.bp;
    bps[index] = {...bps[index],...{[name]:value}}
    this.setState({bp:bps});
  }
  
  handlePesChange(selected,choice)
  {
    var pes = [];
    this.state.pe_list.map((item,index)=>{
	    if(item.id===selected.id) {
		    item.value=choice.value;
        item.show_text_input=choice.has_text_input;
	    }
	    pes.push(item);
    });
    //onsole.log(selected)
    this.setState({pe_list:pes});
  }
  
  handlePesNoteChange(selected,event)
  {
    var pes = [];
    this.state.pe_list.map((item,index)=>{
	    if(item.id===selected.id) {
		    item.note=event.target.value;
	    }
	    pes.push(item);
    });
    this.setState({pe_list:pes});
  }
  
  handlePersonalsChange(id,value)
  {
    var pers = [];
    this.state.personal_list.map((item,index)=>{
	    if(item.id===id) {
		    item.value=value;
	    }
	    pers.push(item);
    });
    this.setState({personal_list:pers});
  }
  
  handlePersonalsNoteChange(id,event)
  {
    var pers = [];
    this.state.personal_list.map((item,index)=>{
	    if(item.id===id) {
		    item.note=event.target.value;
	    }
	    pers.push(item);
    });
    this.setState({personal_list:pers});
  }
  
  addBp()
  {
	  var bps = this.state.bp;
    bps.push({sbp:"",dbp:"",pulse:""});
    this.setState({bp:bps});
  }
  
  removeBp(index)
  {
	  var bps = this.state.bp;
    bps.splice(index,1);
    this.setState({bp:bps});
  }
  
  doSubmit(e,action)
  {
	  e.preventDefault();
	  this.setState({loading:true});
	  var pes = [];
	  this.state.pe_list.map((item,index)=>{
		  pes.push({id:item.id,value:item.value,note:item.note})
	  });
	  
	  var pers = [];
	  this.state.personal_list.map((item,index)=>{
		  if(item.handle==='wt')pers.push({id:item.id,value:this.state.pe.wt,note:null});
		  else if(item.handle==='ht')pers.push({id:item.id,value:this.state.pe.ht,note:null});
		  else if(item.handle==='temp')pers.push({id:item.id,value:this.state.pe.temp,note:null});
		  else pers.push({id:item.id,value:item.value,note:item.note});
	  });
	  var data = {
		  visit_id: this.state.visit.vn,
		  bp: this.state.bp,
		  pe: pes,
		  personal: pers,
      practitioner_id: this.state.practitioner_id
	  }
	  //console.log(data);
	  //console.log(JSON.stringify(data))
	  authFetch.post('pe', data,(result)=>{
		  this.afterSave(result,action)
		});
	  return false;
  }
  
  goBack()
  {
		var backTo = '/management/pe_search';
		if(this.props.location.state && this.props.location.state.backTo) 
		{
			backTo = this.props.location.state.backTo;
		} 
		this.props.history.push(backTo);
  }
  
  afterSave(result, action)
	{
		if(result.success){
      if(action==='print') {
        this.props.history.push('/management/mc_print/'+this.state.visit.vn, { backTo: '/management/pe_search'})
      } else {
        this.goBack();
      }
	  } else {
		  this.setState({loading:false});
		  if(result.errors)window.doAlert(result.errors.join('\n'))
		  else {
			  console.log(result);
			  window.doAlert("Server Response Error");
			}
	  }
	}
  
  render() {
    return (
			<Translate>
			{({ translate }) => 
	      <div className="management-page page">
	      	<header className="page-header">
		      	<button className="link-page-back" onClick={this.goBack}>
	      			{translate("back")}
	      		</button>
		      	<h1>{translate("save_pe_result")}</h1>
		      	<Logout/>
	      	</header>
	      	
	      	<main className="page-content">
		      {this.state.visit?(
		      	<div className="pe-form-wrapper">
		      		<div className="pe-form-body">
			      		<div className="pe-form-left">
				      		<h6 className="text-left">{translate("weight")} - {translate("height")}</h6>
				      		<div className="pe-fields">
						      	<div className="pe-field-row">
						      		<div className="pe-field-group">
							      		<label>{translate("weight")}</label>
							      		<input className="pe-field-input" type="text" name="wt"  
					      				value={this.state.pe.wt?this.state.pe.wt:""} onChange={this.handlePeChange} autoFocus />
					      				<label>{translate("kg")}</label>
						      		</div>
						      		<div className="pe-field-group">
							      		<label>{translate("height")}</label>
							      		<input className="pe-field-input" type="text" name="ht"  
					      				value={this.state.pe.ht?this.state.pe.ht:""} onChange={this.handlePeChange}  />
					      				<label>{translate("cm")}</label>
						      		</div>
						      		<div className="pe-field-group">
							      		<label>{translate("temperature")}</label>
							      		<input className="pe-field-input" type="text" name="temp"  
					      				value={this.state.pe.temp?this.state.pe.temp:""} onChange={this.handlePeChange}  />
					      				<label>°</label>
						      		</div>
					      		</div>
				      		</div>
				      		
				      		<h6 className="text-left">{translate("bp")} - {translate("pulse")}</h6>
				      		<div className="pe-fields">
					      		{this.state.bp.map((item, index)=>{
						      		return (
							      		<div className="pe-field-row" key={index}>
								      		<div className="pe-field-group">
									      		<label>{translate("bp")}</label>
									      		<input className="pe-field-input" type="text" name="sbp"
								      			value={item.sbp} onChange={(event)=>this.handleBpChange(index,event)} />
								      			/
								      			<input className="pe-field-input" type="text" name="dbp"
								      			value={item.dbp} onChange={(event)=>this.handleBpChange(index,event)} />
								      		</div>
								      		<div className="pe-field-group">
									      		<label>{translate("pulse")}</label>
									      		<input className="pe-field-input" type="text" name="pulse"
								      			value={item.pulse} onChange={(event)=>this.handleBpChange(index,event)} />
								      		</div>
								      		<div className="pe-field-row-icon">
									      		{index===0?(null):(
								      			<i onClick={()=>this.removeBp(index)} className="fa fa-trash"></i>
								      			)}
							      			</div>
							      		</div>
					      			)
										})}
					      	</div>
				      		<p className="text-center">
					      		<button type="button" onClick={this.addBp} className="btn btn-round"><i className="fa fa-plus-circle"></i> Add BP/Pulse</button>
					      	</p>
					      	
					      	<br/>
				      		<div className="pe-list">
				      			{this.state.personal_list.map((item, index)=>{
					      			if(item.handle=='wt' || item.handle=='ht' || item.handle=='temp' ||  item.handle=='fasting')return null;
											return (
												<div className="pe-list-item"  key={ index }>
													<div className="pe-list-item-title">{item.name}:</div>
													<div className="pe-list-item-content">
														<div className="pe-list-item-select">
															<button className="pe-list-item-radio" onClick={()=>this.handlePersonalsChange(item.id,"1")}>
																<i className={"far fa-"+((item.value==="1")?'check-circle':'circle')}></i>
																<strong> {translate('dont_have')}</strong>
															</button>
															<button className="pe-list-item-radio" onClick={()=>this.handlePersonalsChange(item.id,"0")}>
																<i className={"far fa-"+((item.value==="0")?'check-circle':'circle')}></i>
																<strong> {translate('have')}</strong>
															</button>
						      					</div>
						      					<div className="pe-list-item-note">
							      					{item.value==="0"?(
							      					<input type="text" className="pe-note" onChange={(event)=>this.handlePersonalsNoteChange(item.id,event)} value={item.note} />
							      					):(null)}
							      				</div>
													</div>
												</div>
											)
								    })}
                      
				      			{this.state.personal_list.map((item, index)=>{
					      			if(item.handle!=='fasting')return null;
											return (
												<div className="pe-list-item"  key={ index }>
													<div className="pe-list-item-title">{item.name}:</div>
													<div className="pe-list-item-content">
														<div className="pe-list-item-select">
															<button className="pe-list-item-radio" onClick={()=>this.handlePersonalsChange(item.id,"0")}>
																<i className={"far fa-"+((item.value==="0")?'check-circle':'circle')}></i>
																<strong> งด</strong>
															</button>
															<button className="pe-list-item-radio" onClick={()=>this.handlePersonalsChange(item.id,"1")}>
																<i className={"far fa-"+((item.value==="1")?'check-circle':'circle')}></i>
																<strong> ไม่งด</strong>
															</button>
						      					</div>
						      					<div className="pe-list-item-note">
							      					<input type="text" className="pe-note" onChange={(event)=>this.handlePersonalsNoteChange(item.id,event)} value={item.note} />
							      				</div>
													</div>
												</div>
											)
								    })}
				      		</div>

                  <div className="pe-fields">
						      	<div className="pe-field-row">
                      {this.state.practitioners?(
                          <div className="pe-list-item">
                            <div className="pe-list-item-title">{translate("select_doctor")}</div>
                            <div className="pe-list-item-content">
                              <select className="form-control" onChange={this.handlePractitionerChange} 
                              value={this.state.practitioner_id?this.state.practitioner_id:""}>
                                <option value="">{translate("not_specific")}</option>
                                {this.state.practitioners.map((item, index)=>{
                                  return (<option value={item.id} key={index}>{item.fullname}</option>)
                                })}
                              </select>
                            </div>
                          </div>
                      ):null}
                    </div>
                  </div>

			      		</div>
			      		<div className="pe-form-right">
				      		<h6 className="text-left">{translate("assessment")}</h6>
				      		<div className="pe-list">
				      			{this.state.pe_list.map((item, index)=>{
											return (
												<div className="pe-list-item"  key={ index }>
													<div className="pe-list-item-title">{item.name}:</div>
													{item.choices===null || item.choices.length===0?(
														<div className="pe-list-item-comment">
								      				<input type="text" className="pe-note" onChange={(event)=>this.handlePesNoteChange(item,event)} value={item.note} />
														</div>
													):(
														<div className="pe-list-item-content">
															<div className="pe-list-item-select">
                                {item.choices.map((choice, pindex)=>{
                                  return <button className="pe-list-item-radio" key={pindex} onClick={()=>this.handlePesChange(item,choice)}>
                                    <i className={"far fa-"+((item.value===choice.value)?'check-circle':'circle')}></i>
                                    <strong><small> {choice.title}</small></strong>
                                  </button>
                                })}
							      					</div>
							      					<div className="pe-list-item-note">
								      					{item.show_text_input?(
								      					<input type="text" className="pe-note" onChange={(event)=>this.handlePesNoteChange(item,event)} value={item.note} />
								      					):(null)}
								      				</div>
														</div>
													)}
												</div>
											)
								    })}
				      		</div>
			      		</div>
		      		</div>
		      		<div className="pe-form-footer">
				  			<div className="pe-form-footer-info">
				  				<p><strong>{translate("name")}</strong>: {this.state.visit.fullname}</p>
				  				<p><strong>{translate("vn")}</strong>: {this.state.visit.vn}</p>
				  			</div>

				  			<div className="pe-form-footer-button">
                  <button className="btn-round btn-block" onClick={(event)=>this.doSubmit(event,'print')}>{translate("save_and_print_data")}</button>
                </div>
                &nbsp;
				  			<div className="pe-form-footer-button">
						  		<button className="btn-round btn-block" onClick={(event)=>this.doSubmit(event)}>{translate("save_data")}</button>
					  		</div>
		      		</div>
		      	</div>
		      ):(null)}
		      <Loading show={this.state.loading}/>
		      </main>
	      </div>
      }
			</Translate>
    );
  }
}

export default ManagementPEForm;
