import React from "react"
import { RadioBox } from "./helper";
import { Translate } from "react-localize-redux";

const ans = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',	
]
export class NearLateral extends RadioBox {
    constructor(props) {
        super(props, props.setState)
    }
	render() {
		return (
			<Translate key={this.props.template.id}>
                {({ translate }) => 
                    <tr>
                        <td colSpan="2">
                           12. {this.props.template.name}
                        </td>
                        {
                            ans.map((x, index) => 
                                <td
                                    key={index}
                                    className={`
                                        ${x <= 3 ? "highlight": ''}
                                        ${x >= 13 ? "highlight": ''}
                                    `.trim()}
                                >
                                    <label
                                        className={this.state.value === `${x}` ? 'active': ''}
                                    >
                                        <input
                                            name="near-left-vision"
                                            type="radio"
                                            value={`${x}`}
                                            onChange={(e) => this.onChangeHandler(e)}
                                            onClick={e => this.onClickHandler(e)}
                                            checked={this.state.value === `${x}`}
                                        />
                                        {x}
                                    </label>
                                </td>
                            )
                        }
                    </tr>
				}
			</Translate>
		)
	}
}