import React from 'react'
import { ProtectorType } from './ProtectorType'
import { ProtectorUsed } from './ProtectorUsed'

export class ProtectorTypeWrapper extends React.Component {
    state = {
        used: ''
    }

    used;
    type;
    constructor(props) {
        super(props)
        this.used = props.template.data.find(x => x.handle === 'protector')
        this.type = props.template.data.find(x => x.handle === 'protector_type')
    }

    componentDidMount() {
        const newState = {}
        newState['used'] = this.used.value
        this.setState(newState)
    }


    set(props, newState) {
        this.setState({
            used: newState.value
        }, () => this.props.setState(props, newState))
    }

    render() {
        return (
            <div className="oc-form-two-column">
                <ProtectorUsed template={this.used} setState={this.set.bind(this)}></ProtectorUsed>
                { 
                    this.state.used !== '' && this.state.used !== 'never' ?
                        <ProtectorType template={this.type} setState={this.set.bind(this)}></ProtectorType>
                    : null
                }
            </div>
        )
    }
}